import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './app/pages/login';
import Page401 from './app/pages/page_401';
import Page404 from './app/pages/page_404';
import Loading from './common/components/Loading';

const CuentaRouter = React.lazy(() => import('./modules/cuenta/CuentaRouter'));
const ServiceDeskRouter = React.lazy(
  () => import('./modules/serviceDesk/ServiceDeskRouter')
);
const SolicitudRouter = React.lazy(
  () => import('./modules/solicitud/SolicitudRouter')
);

const MainRouter = () => (
  <Routes>
    <Route
      path="cuenta/*"
      element={
        <React.Suspense
          fallback={<Loading className="max-w-md mx-auto my-4" hasIcon />}
        >
          <CuentaRouter />
        </React.Suspense>
      }
    />
    <Route
      path="serviceDesk/*"
      element={
        <React.Suspense
          fallback={<Loading className="max-w-md mx-auto my-4" hasIcon />}
        >
          <ServiceDeskRouter />
        </React.Suspense>
      }
    />

    <Route
      path="solicitud/*"
      element={
        <React.Suspense
          fallback={<Loading className="max-w-md mx-auto my-4" hasIcon />}
        >
          <SolicitudRouter />
        </React.Suspense>
      }
    />

    <Route path="login" element={<Login />} />
    <Route path="/" element={<Navigate to="/cuenta" replace />} />
    <Route path="/401" element={<Page401 />} />
    <Route path="*" element={<Page404 />} />
  </Routes>
);

export default MainRouter;
