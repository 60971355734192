import { Reducer } from "react";
import { User } from "../../common/interfaces/user";

export interface SessionState {
  user?: User;
  loading: boolean;
  hasAccess: boolean;
  expiresAt?: Date;
}

export const INITIAL_SESSION: SessionState = {
  loading: true,
  hasAccess: false,
};
export const sessionReducer: Reducer<
  SessionState,
  | {
      type: "session/set";
      user?: User;
      expiresAt?: Date;
    }
  | {
      type: "session/remove";
    }
> = (state = INITIAL_SESSION, action) => {
  switch (action.type) {
    case "session/set":
      state = {
        loading: false,
        hasAccess: !!action.user,
        user: action.user,
        expiresAt: action.expiresAt && new Date(action.expiresAt),
      };
      break;
    case "session/remove":
      state = {
        loading: false,
        hasAccess: false,
        user: undefined,
      };
      break;
  }
  return state;
};
