import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loginSocket, logoutSocket } from '../actions/session.actions';
import { SessionState } from '../reducers/session.reducer';
import socketClient from '../services/socketClient';
import { RootState } from '../store';

function ConnectionStatus() {
  const [connected, setConnected] = useState(true);

  const { user } = useSelector<RootState, SessionState>(
    (state) => state.session
  );

  useEffect(() => {
    const handler = () => {
      setConnected(socketClient.connected);
    };
    socketClient.on('connect', handler);
    socketClient.on('reconnect', handler);
    socketClient.on('reconnect_error', handler);
    socketClient.on('disconnect', handler);
    socketClient.on('error', handler);
    return () => {
      socketClient.removeListener('connect', handler);
      socketClient.removeListener('reconnect', handler);
      socketClient.removeListener('reconnect_error', handler);
      socketClient.removeListener('disconnect', handler);
      socketClient.removeListener('error', handler);
    };
  }, []);

  useEffect(() => {
    if (connected) {
      if (user) {
        loginSocket();
      } else {
        logoutSocket();
      }
    }
  }, [connected, user]);

  return (
    <div className="fixed bottom-8">
      {!connected && (
        <FontAwesomeIcon
          icon={faWarning}
          className="text-yellow-500 m-2 animate-pulse"
          title="No hay conexión con el servidor"
          size="2x"
        />
      )}
    </div>
  );
}

export default ConnectionStatus;
