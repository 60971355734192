import { Action, Reducer } from "redux";

export type LoadingState = string[];

export interface LoadingActions extends Action {
  type: "loading/start" | "loading/end";
  paths: string[];
}

export const loadingReducer: Reducer<LoadingState, LoadingActions> = (
  state = [],
  action
) => {
  switch (action.type) {
    case "loading/start":
      state = [...state, ...action.paths];
      break;
    case "loading/end":
      state = state.filter((loading_path) => !action.paths.includes(loading_path));
      break;
  }
  return state;
};
