import { PropsWithChildren } from 'react';
import { Modal } from '../modal';
import StatusIcon from './StatusIcon';

const StatusModal = ({
  status,
  error,
  children,
}: PropsWithChildren<{
  status: 'none' | 'success' | 'error' | 'loading';
  error?: number | string;
}>) => (
  <Modal
    isOpen={status !== 'none'}
    windowClass="!max-w-min"
    closeBtn={false}
    closeOnOutSide={false}
  >
    <StatusIcon status={status} className="p-2 mx-auto" />
    {children}
    {error && (errorsLabel[error + ''] || error)}
  </Modal>
);

const errorsLabel: { [key: string]: string } = {
  '400': 'Error en los datos enviados',
  '401': 'No autorizado',
  '403': 'Contenido inaccesible',
  '404': 'No encontrado',
  '500': 'Error en el servidor',
};

export default StatusModal;
