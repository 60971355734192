import { PropsWithChildren, useEffect, useState } from "react";

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faTimes,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

export type StatusIconProps = PropsWithChildren<{
  status?: "none" | "error" | "loading" | "success";
  colorLoading?: string;
  colorError?: string;
  colorSuccess?: string;
  iconSize?: "2x" | "3x" | "4x" | "5x";
  hideIcon?: boolean;
  className?: string;
}>;

export function StatusIcon({
  status = "none",
  colorLoading = "#0ea5e9",
  colorError = "#dc2626",
  colorSuccess = "#22c55e",
  iconSize = "2x",
  hideIcon = false,
  children,
  className,
}: StatusIconProps) {
  const [iconProps, setIconProps] = useState<FontAwesomeIconProps>({
    icon: "0",
  });
  useEffect(() => {
    switch (status) {
      case "loading":
        setIconProps({
          icon: faCircleNotch,
          spin: true,
          size: iconSize,
          style: {
            color: colorLoading,
          },
        });
        break;

      case "error":
        setIconProps({
          icon: faTimes,
          size: iconSize,
          style: {
            color: colorError,
          },
        });
        break;

      case "success":
        setIconProps({
          icon: faCheckCircle,
          size: iconSize,
          style: {
            color: colorSuccess,
          },
        });
        break;
      default:
        break;
    }
  }, [status, colorSuccess, colorError, colorLoading, iconSize]);
  return (
    <>
      {status !== 'none' && (
        <div className={className}>
          {!hideIcon && <FontAwesomeIcon {...iconProps} />}
          {children}
        </div>
      )}
    </>
  );
}

export default StatusIcon;
