import { useEffect, useState } from "react";
import { LeftSidebar } from "./Layout";

function DarkSwitch() {
  const [darkTheme, setDarkTheme] = useState<boolean>(
    !!window.localStorage.getItem("darkTheme")
  );

  useEffect(() => {
    const htmlElement = document.querySelector("html");
    if (darkTheme) {
      htmlElement?.classList.add("dark");
    } else {
      htmlElement?.classList.remove("dark");
    }
  }, [darkTheme]);

  return (
    <LeftSidebar position="bottom">
      <div className="mx-auto p-2">
        <label>Modo oscuro </label>
        <label className="switch">
          <input
            type={"checkbox"}
            checked={darkTheme}
            onChange={(ev) => {
              if (ev.target.checked) {
                window.localStorage.setItem("darkTheme", "true");
                setDarkTheme(true);
              } else {
                window.localStorage.removeItem("darkTheme");
                setDarkTheme(false);
              }
            }}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </LeftSidebar>
  );
}

export default DarkSwitch;
