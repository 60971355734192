import { createContext, PropsWithChildren, useState } from 'react';
import { Portal } from '../../common/components/Portal';
import { Sidebar } from '../../common/components/Sidebar';
import ConnectionStatus from './ConnectionStatus';

export const LayoutContext = createContext<{
  sidebarL: {
    open: () => void;
    close: () => void;
    hidden: boolean;
  };
  sidebarR: {
    open: () => void;
    close: () => void;
    hidden: boolean;
  };
}>({
  sidebarL: {
    open: () => {},
    close: () => {},
    hidden: false,
  },
  sidebarR: {
    open: () => {},
    close: () => {},
    hidden: false,
  },
});

export function Layout({ children }: PropsWithChildren<{}>) {
  const [hiddenSidebarL, setHiddenSidebarL] = useState<boolean>(true);
  const [hiddenSidebarR, setHiddenSidebarR] = useState<boolean>(true);

  return (
    <div className="h-screen relative flex flex-col">
      <nav className="max-h-fit top-0 inset-x-0 content-theme border-theme border-b  flex flex-wrap z-50">
        <div data-navbar-left className="order-first "></div>
        <div
          data-navbar-center
          className="mx-auto order-last sm:order-1 w-full sm:w-fit flex justify-center"
        ></div>
        <div data-navbar-right className=" order-2"></div>
      </nav>
      <div className="h-full flex flex-row relative overflow-y-auto overflow-x-hidden">
        <Sidebar
          classNameOnOpen="absolute sm:relative"
          className="z-50"
          hidden={hiddenSidebarL}
        >
          <div data-left-sidebar-top className="flex flex-col "></div>
          <div
            data-left-sidebar-middle
            className="my-auto h-fit flex align-middle flex-col"
          ></div>
          <div
            data-left-sidebar-bottom
            className="mt-auto flex flex-col mb-2"
          ></div>
        </Sidebar>
        <div className="w-full overflow-auto">
          <LayoutContext.Provider
            value={{
              sidebarL: {
                open: () => setHiddenSidebarL(false),
                close: () => setHiddenSidebarL(true),
                hidden: hiddenSidebarL,
              },
              sidebarR: {
                open: () => setHiddenSidebarR(false),
                close: () => setHiddenSidebarR(true),
                hidden: hiddenSidebarR,
              },
            }}
          >
            {children}
          </LayoutContext.Provider>
        </div>
        <Sidebar
          classNameOnOpen="absolute sm:relative right-0"
          className="z-40 "
          hidden={hiddenSidebarR}
          right
        >
          <div data-right-sidebar-top className="flex flex-col mt-2"></div>
          <div
            data-right-sidebar-middle
            className="my-auto h-fit flex align-middle flex-col"
          ></div>
          <div
            data-right-sidebar-bottom
            className="mt-auto flex flex-col mb-2"
          ></div>
        </Sidebar>
      </div>
      <nav
        data-navbar-bottom
        className=" max-h-fit inset-x-0 bottom-0 content-theme border-theme border-t flex flex-wrap justify-center"
      ></nav>
      <ConnectionStatus />
    </div>
  );
}

export const LeftSidebar = ({
  children,
  className,
  position,
}: PropsWithChildren<{
  className?: string;
  position: 'top' | 'middle' | 'bottom';
}>) => (
  <Portal
    children={children}
    className={className}
    selector={`div[data-left-sidebar-${position}]`}
  />
);

export const RightSidebar = ({
  children,
  className,
  position,
}: PropsWithChildren<{
  className?: string;
  position: 'top' | 'middle' | 'bottom';
}>) => (
  <Portal
    children={children}
    className={className}
    selector={`div[data-right-sidebar-${position}]`}
  />
);

export const Navbar = ({
  children,
  className,
  position,
}: PropsWithChildren<{
  className?: string;
  position: 'left' | 'center' | 'right';
}>) => (
  <Portal
    children={children}
    className={className}
    selector={`div[data-navbar-${position}]`}
  />
);

export const NavbarBottom = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <Portal
    children={children}
    className={className}
    selector="nav[data-navbar-bottom]"
  />
);
