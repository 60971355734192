import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import * as yup from 'yup';
import DarkSwitch from './app/components/DarkSwitch';
import { Layout } from './app/components/Layout';
import { SessionManager } from './app/components/SessionManager';
import store from './app/store';
import { ModalProvider } from './common/modal';
import MainRouter from './MainRouter';

function App() {
  useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    if (isProduction && 'serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('sw.js')
        .then(()=>{
          
        })
        .catch(console.warn);
    }
  }, []);
  return (
    <Provider store={store}>
      <ModalProvider>
        <Layout>
          <DarkSwitch />
          <SessionManager />
          <MainRouter />
        </Layout>
      </ModalProvider>
    </Provider>
  );
}

export default App;

yup.setLocale({
  string: {
    min: ({ min, label }) => {
      return `${
        label ? label + ' debe' : 'Debe'
      } tener mínimo ${min} caracteres`;
    },
    max: ({ max, label }) => {
      return `${
        label ? label + ' debe' : 'Debe'
      } tener máximo ${max} caracteres`;
    },
    email: ({ label }) => {
      return `${
        label ? label + ' debe' : 'Debe'
      } ser un correo electrónico válido`;
    },
  },
  mixed: {
    /*required: ({ label }) => {
      return `${label || "El campo"} es necesario`;
    },*/
    defined: ({ label }) => {
      return `${label || 'El campo'} es necesario`;
    },
    required: ({ label }) => {
      return `${label || 'El campo'} es necesario`;
    },
  },
});
