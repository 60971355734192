import { useContext } from 'react';
import { Subject } from 'rxjs';
import { ConfirmModalChildren } from './confirm.modal';
import { ModalOptions } from './modal';
import { ModalServiceContext } from './modal.provider';
import { PropertyModalChildren } from './property.modal';
//import { PropertyModalChildren } from "./property.modal";

export const useCreateModal = () => useContext(ModalServiceContext).createModal;

export const usePropertyModal = () => {
  let createModal = useContext(ModalServiceContext).createModal;
  const createPropertyModal: <T = any>(
    children: React.ReactNode,
    options: ModalOptions & {
      initialValues: any;
      yes?: string;
      no?: string | null;
    }
  ) => Promise<T | false> = (
    children,
    { yes, no, showController, initialValues, ...rest }
  ) => {
    return new Promise((resolve) => {
      let showSubject = new Subject<boolean>();
      if (showController) {
        showController.subscribe(showSubject.next);
      }

      let propertyModalChildren = (
        <PropertyModalChildren
          initialValues={initialValues}
          onNoHandler={() => {
            showSubject.next(false);
            resolve(false);
          }}
          onSubmitHandler={(values) => {
            showSubject.next(false);
            resolve(values);
          }}
          children={children}
          no={no}
          yes={yes}
        />
      );

      createModal(propertyModalChildren, {
        ...rest,
        showController: showSubject.asObservable(),
        closeOnOutSide: true,
        destroyOnHide: true,
      });
    });
  };
  return createPropertyModal;
};

export const useConfirmModal = () => {
  let createModal = useContext(ModalServiceContext).createModal;
  return (
    children: React.ReactNode,
    {
      yes,
      no,
      showController,
      ...rest
    }: ModalOptions & {
      yes?: string;
      no?: string | null;
    }
  ) => {
    return new Promise<any>((resolve) => {
      let showSubject = new Subject<boolean>();
      if (showController) {
        showController.subscribe(showSubject.next);
      }
      const responseHandler = (response: boolean) => {
        showSubject.next(false);
        resolve(response);
      };

      let confirmModalChildren = (
        <ConfirmModalChildren
          responseHandler={responseHandler}
          children={children}
          no={no}
          yes={yes}
        />
      );

      createModal(confirmModalChildren, {
        ...rest,
        showController: showSubject.asObservable(),
        closeOnOutSide: true,
        destroyOnHide: true,
      });
    });
  };
};
