import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Location, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

import EffectBox from '../../common/components/EffectBox';
import Loading from '../../common/components/Loading';
import { login, login2fa } from '../actions/session.actions';
import { SessionState } from '../reducers/session.reducer';
import { RootState } from '../store';
import useLoadingStatus from '../../common/hooks/useLoadingStatus.hook';
import StatusModal from '../../common/components/StatusModal';

type LocationState =
  | undefined
  | {
      from: Location;
    };

function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  let { loading, hasAccess } = useSelector<RootState, SessionState>(
    (state) => state.session
  );

  let { createLoadingControl, status, refresh, lastError } = useLoadingStatus(
    (error) => {
      if (error) {
        console.error(error);
        setTimeout(() => {
          refresh();
        }, 1500);
      }
    }
  );
  let [isOtp, setIsOtp] = useState(false);
  let redirectTo = (location.state as LocationState)?.from || '/cuenta';

  if (loading) {
    return <Loading className="max-w-md mx-auto my-4" hasIcon />;
  }

  if (hasAccess) {
    return <Navigate to={redirectTo} replace />;
  }

  const loginhandler = (values: any) => {
    dispatch(
      login(
        values.username,
        values.password,
        values.remember,
        createLoadingControl(),
        (isAuthenticator) => {
          if (isAuthenticator) {
            setIsOtp(true);
            refresh();
          } else {
            navigate(redirectTo, {
              replace: true,
            });
          }
        }
      )
    );
  };

  const login2faHandler = (values: any) => {
    dispatch(
      login2fa(
        values.otp,
        createLoadingControl((error) => {
          if (error) {
            console.error(error);
            setTimeout(() => {
              refresh();
            }, 1500);
          } else {
            navigate(redirectTo, {
              replace: true,
            });
          }
        })
      )
    );
  };

  return (
    <EffectBox
      className={
        'mx-auto content-theme my-5 p-4 max-w-sm border border-theme ' +
        (status === 'error' && 'opacity-70')
      }
      shadow
      pulse={status === 'loading'}
    >
      {!isOtp ? (
        <FormLogin onSubmit={loginhandler} disabled={status === 'loading'} />
      ) : (
        <FormOtp onSubmit={login2faHandler} disabled={status === 'loading'} />
      )}
      <StatusModal status={status}>
        {!!lastError &&
          (lastError === 429
            ? ' Límite de intentos excedido'
            : ' Datos inválidos')}
      </StatusModal>
    </EffectBox>
  );
}

export default Login;

const FormLogin = ({
  onSubmit,
  disabled,
}: {
  onSubmit: (values: {
    username: string;
    password: string;
    remember: boolean;
  }) => void;
  disabled?: boolean;
}) => (
  <Formik
    initialValues={{
      username: '',
      password: '',
      remember: false,
    }}
    onSubmit={onSubmit}
    validationSchema={yup.object({
      username: yup.string().required(),
      password: yup.string().required(),
      remember: yup.boolean().optional(),
    })}
  >
    <Form className="grid grid-cols-1 gap-3">
      <h2>Iniciar sesion</h2>
      <div className="pl-2">
        <label htmlFor="username">Código:</label>
        <Field
          name="username"
          type="text"
          placeholder="Ingresa tu código"
          disabled={disabled}
        />
      </div>
      <div className="pl-2">
        <label htmlFor="password">NIP:</label>
        <Field
          name="password"
          type="password"
          placeholder="Ingresa tu NIP"
          disabled={disabled}
        />
      </div>
      <div className="pl-2">
        <Field name="remember" type="checkbox" />
        <label htmlFor="remember">Mantener la sesión abierta</label>
      </div>
      <div>
        <p>
          Para recuperar su NIP, puede hacerlo desde{' '}
          <a
            href="http://siiauescolar.siiau.udg.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SIIAU
          </a>
        </p>
        <button type="submit" className="btn-main" disabled={disabled}>
          Iniciar sesión
        </button>
      </div>
    </Form>
  </Formik>
);

const FormOtp = ({
  onSubmit,
  disabled,
}: {
  onSubmit: (values: { otp: string }) => void;
  disabled?: boolean;
}) => (
  <Formik
    initialValues={{
      otp: '',
    }}
    onSubmit={onSubmit}
    validationSchema={yup.object({
      otp: yup.string().max(10).min(6).required(),
    })}
  >
    <Form className="grid grid-cols-1 gap-3">
      <h2 className="flex justify-center">Verifica tu identidad</h2>
      <div className="flex flex-wrap justify-center">
        <label htmlFor="otp">
          Ingresa el código de verificación de tu aplicación de autenticación o
          una clave de recuperación:
        </label>
        <Field
          name="otp"
          type="text"
          placeholder="######"
          disabled={disabled}
        />
      </div>
      <div>
        <button type="submit" className="btn-main" disabled={disabled}>
          Verificar
        </button>
      </div>
    </Form>
  </Formik>
);
