import React, { useEffect, useMemo } from 'react';
import {Subject} from 'rxjs';
import { Modal, ModalProps } from './modal';

export function ConfirmModal({
    yes,
    no,
    resolve,
    children,
    showController,
    ...rest
}: ModalProps & {
    yes?: string;
    no?: string | null;
    resolve: (value: boolean) => void;
}) {
    let showSubject = useMemo(() => new Subject<boolean>(), []);

    useEffect(() => {
        if (showController) {
            let subscription = showController.subscribe(showSubject.next);
            return () => {
                subscription.unsubscribe();
            };
        }
    }, [showController,showSubject.next]);

    const responseHandler = (response: boolean) => {
        showSubject.next(false);
        resolve(response);
    };

    return (
        <Modal
            {...rest}
            closeOnOutSide={true}
            showController={showSubject.asObservable()}
        >
            <ConfirmModalChildren
                responseHandler={responseHandler}
                children={children}
                no={no}
                yes={yes}
            />
        </Modal>
    );
}

export const ConfirmModalChildren = ({
    yes,
    no,
    children,
    responseHandler,
}: {
    children?: React.ReactNode;
    yes?: string;
    no?: string | null;
    responseHandler: (response: boolean) => void;
}) => (
    <>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
            <button
                className="btn btn-main"
                onClick={() => responseHandler(true)}
            >
                {yes || confirmModalDefaults.yesLabel}
            </button>
            {no !== null && (
                <button
                    className="btn btn-theme"
                    onClick={() => responseHandler(false)}
                >
                    {no || confirmModalDefaults.noLabel}
                </button>
            )}
        </div>
    </>
);

export const confirmModalDefaults: {
    yesLabel: string;
    noLabel: string;
} = {
    yesLabel: 'Yes',
    noLabel: 'No',
};
