import { useCallback, useState } from 'react';

export interface LoadingControl {
  error?: any;
  setLoading: (isLoading: boolean) => void;
  setError: (error: any) => void;
  loading: boolean;
}

export function useLoadingStatus(after: (error?: any) => void = () => {}) {
  const [status, setStatus] = useState<
    'none' | 'loading' | 'error' | 'success'
  >('none');
  const [lastError, setLastError] = useState<any>(undefined);
  const refresh = useCallback(() => {
    setStatus('none');
    setLastError(undefined);
  }, []);
  const createLoadingControl = useCallback(
    (_after: (error?: any) => void = after) => {
      let loadingControl: LoadingControl = {
        error: undefined,
        loading: false,
        setLoading: (isLoading) => {
          loadingControl.loading = isLoading;
          if (isLoading) {
            setLastError(undefined);
            setStatus('loading');
          } else if (loadingControl.error) {
            setStatus('error');
            setLastError(loadingControl.error);
            _after(loadingControl.error);
          } else {
            setStatus('success');
            setLastError(undefined);
            _after();
          }
        },
        setError: (error) => {
          loadingControl.error = error;
        },
      };
      return loadingControl;
    },
    [after]
  );
  return {
    status,
    lastError,
    setStatus,
    refresh,
    createLoadingControl,
  };
}

export default useLoadingStatus;
