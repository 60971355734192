import { LoadingControl } from '../hooks/useLoadingStatus.hook';
import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API || '/api',
});

const API_URL = process.env.REACT_APP_URL_API || '/api';

export const httpApi = async (
  path: string,
  {
    query,
    toJson = true,
    loadingControl,
    ...init
  }: RequestInit & {
    query?: {
      [key: string]: string;
    };
    toJson?: boolean;
    loadingControl?: LoadingControl;
  } = {}
) => {
  try {
    loadingControl?.setLoading(true);
    let queryString = queryToString(query);

    if (
      !(init.body instanceof FormData) &&
      !(init.body instanceof URLSearchParams)
    ) {
      init.headers = {
        'Content-type': 'application/json',
        ...init.headers,
      };
    }

    const response = await fetch(`${API_URL}/${path}${queryString}`, init);

    if (!response.ok) {
      throw response.status;
    }

    const responseData = toJson ? await response.json() : response;

    loadingControl?.setLoading(false);

    return responseData;
  } catch (err) {
    if (loadingControl) {
      loadingControl?.setError(err);
      loadingControl?.setLoading(false);
    } else {
      throw err;
    }
  }
};

const queryToString = (query?: { [key: string]: string }) =>
  query
    ? '?' +
      Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    : '';

export const JSONToForm_urlencoded = (object: { [key: string]: any }) =>
  new URLSearchParams(Object.entries(object));
export const JSONToForm_data = (object: { [key: string]: any }) => {
  let reqBody = new FormData();
  for (const name in object) {
    reqBody.append(name, object[name]);
  }
  return reqBody;
};
