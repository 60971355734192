import { PropsWithChildren, useLayoutEffect, useState } from 'react';
import ReactDom from 'react-dom';

export const Portal = ({
  children,
  selector,
  className
}: PropsWithChildren<{
  selector: string;
  className?: string;
}>) => {
  let [element, setElement] = useState(document.querySelector(selector));

  useLayoutEffect(() => {
    const el = document.querySelector(selector);
    setElement(el);
    if(className){
      el?.classList.add(...className.split(' '))
    }
  }, [selector, className]);


  if (element) {
    return ReactDom.createPortal(children, element);
  } else {
    setTimeout(() => {
      setElement(document.querySelector(selector));
    }, 200);
    return <></>;
  }
};
