import { User } from '../../common/interfaces/user';
import ProfileImage from '../../common/components/ProfileImage';

interface AccountMinifiedProps {
  user: User;
  withImg?: boolean;
}

const AccountMinified = ({ user, withImg }: AccountMinifiedProps) => (
  <div className="flex flex-wrap">
    {withImg && (
      <div className="w-full flex justify-center">
        <ProfileImage codigo={user.codigo} widthHeight="50px"/>
      </div>
    )}
    <div className="w-full justify-center">
      <p>
        <b>Código: </b> {user.codigo}
      </p>
      <p>
        {user.nombre} {user.apellidos}
      </p>
    </div>
  </div>
);

export default AccountMinified;
