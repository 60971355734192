import EffectBox from "./EffectBox";
import StatusIcon from "./StatusIcon";

const Loading = ({ className, hasIcon }: { className?: string; hasIcon?: boolean }) => (
  <EffectBox
    pulse
    shadow
    className={"m-2 content-theme p-4 w-full border border-theme " + className}
  >
    <div className="grid grid-cols-3 gap-2">
      <div className="col-span-3 h-2 dark:bg-neutral-600 bg-neutral-200 rounded"></div>
      <div className="col-span-3 h-2"></div>
      <div className="col-span-2 h-2 dark:bg-neutral-600 bg-neutral-200 rounded"></div>
      <div className="col-span-1 h-2 dark:bg-neutral-600 bg-neutral-200 rounded"></div>
      <div className="col-span-1 h-2 dark:bg-neutral-600 bg-neutral-200 rounded"></div>
      <div className="col-span-2 h-2 dark:bg-neutral-600 bg-neutral-200 rounded"></div>
    </div>
    <StatusIcon status="loading" hideIcon={!hasIcon} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"/>
  </EffectBox>
);

export default Loading;
