import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

function ProfileImage({
  codigo,
  className,
  hash,
  widthHeight = '200px',
}: {
  codigo?: string;
  className?: string;
  widthHeight?: string;
  hash?: string;
}) {
  const [err, setError] = useState<any>(null);

  useEffect(() => {
    setError(null);
  }, [codigo]);

  return codigo ? (
    <div
      className={[
        'flex items-center justify-center',
        'rounded-full relative overflow-hidden',
        'border-4 border-theme',
        className || '',
      ].join(' ')}
      style={{
        width: widthHeight,
        height: widthHeight,
      }}
    >
      {err ? (
        <FontAwesomeIcon icon={faUserCircle} className="h-full w-full" />
      ) : (
        <img
          src={`/api/imagen/perfil/${codigo}${hash ? `?t=${hash}` : ''}`}
          alt="Imagen de perfil"
          className="h-full"
          onError={setError}
        />
      )}
    </div>
  ) : null;
}
export default ProfileImage;
