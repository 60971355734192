import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { httpApi, JSONToForm_urlencoded } from '../../common/services/httpApi';
import { User } from '../../common/interfaces/user';
import { LoadingControl } from '../../common/hooks/useLoadingStatus.hook';
import socketClient from '../services/socketClient';

export interface SetSessionAction extends Action {
  type: 'session/set';
  user?: User;
  expiresAt?: Date;
}
export interface RemoveSessionAction extends Action {
  type: 'session/remove';
}

export type SessionActions = SetSessionAction | RemoveSessionAction;

export type SessionThunkActions = ThunkAction<any, any, any, SessionActions>;

export const login =
  (
    username: string,
    password: string,
    remember: boolean,
    loadingControl: LoadingControl,
    listener?: (isAuthenticator?: string) => void
  ): SessionThunkActions =>
  async (dispatch) => {
    try {
      const body = await httpApi('cuenta/login', {
        method: 'POST',
        body: JSONToForm_urlencoded({ username, password, remember }),
        loadingControl,
      });
      if (!body) {
        return;
      }
      if ('authenticator' in body) {
        dispatch({ type: 'session/remove' });
        if (listener) listener('authenticator');
      } else {
        socketClient.close();
        socketClient.connect();
        dispatch({
          type: 'session/set',
          user: body.user,
          expireAt: body.accessTokenExpiresAt,
        });
        if (body.accessTokenExpiresAt) {
          setTimeout(() => {
            dispatch({ type: 'session/remove' });
          }, new Date(body.accessTokenExpiresAt).getTime() - Date.now());
        }
        if (listener) listener();
      }
    } catch (err) {
      console.error(err);
    }
  };

export const logout = (): SessionThunkActions => async (dispatch) => {
  try {
    const response = await httpApi('cuenta/logout', {
      method: 'DELETE',
      toJson: false,
    });
    if (!response.ok) {
      throw response.status;
    }
    dispatch({ type: 'session/remove' });
  } catch (err) {
    console.error(err);
  }
};

export const clearCache = (): SessionThunkActions => async (dispatch) => {
  try {
    const response = await httpApi('clear-cache', {
      method: 'DELETE',
      toJson: false,
    });
    if (!response.ok) {
      throw response.status;
    }
    dispatch({ type: 'session/remove' });
  } catch (err) {
    console.error(err);
  }
};

export const login2fa =
  (otp: string, loadingControl: LoadingControl): SessionThunkActions =>
  async (dispatch) => {
    try {
      const token = await httpApi('cuenta/login/2fa', {
        method: 'POST',
        body: JSON.stringify({
          otp,
        }),
        loadingControl,
      });
      socketClient.close();
      socketClient.connect();
      dispatch({
        type: 'session/set',
        user: token.user,
        expireAt: token.accessTokenExpiresAt,
      });
      if (token.accessTokenExpiresAt) {
        setTimeout(() => {
          dispatch({ type: 'session/remove' });
        }, new Date(token.accessTokenExpiresAt).getTime() - Date.now());
      }
    } catch (err) {
      console.error(err);
    }
  };

export const getTokenData = (): SessionThunkActions => (dispatch) => {
  httpApi('oauth/token')
    .then((token) => {
      socketClient.close();
      socketClient.connect();
      dispatch({
        type: 'session/set',
        user: token.user,
        expireAt: token.accessTokenExpiresAt,
      });
      if (token.accessTokenExpiresAt) {
        setTimeout(() => {
          dispatch({ type: 'session/remove' });
        }, new Date(token.accessTokenExpiresAt).getTime() - Date.now());
      }
    })
    .catch((error) => {
      dispatch({ type: 'session/remove' });

      console.error(error);
    });
};

export const loginSocket = () => {
  const interval = setInterval(() => {
    if (socketClient.connected) {
      clearInterval(interval);
      socketClient.emit('login');
    }
  }, 500);
};

export const logoutSocket = () => {
  socketClient.emit('logout');
};
