import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCache, getTokenData, logout } from '../actions/session.actions';
import { SessionState } from '../reducers/session.reducer';
import { RootState } from '../store';
import { LayoutContext, LeftSidebar, Navbar } from './Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import AccountMinified from './AccountMinified';
import { NavLink } from 'react-router-dom';
import AccessList from './AccessList';

export function SessionManager() {
  const dispatch = useDispatch();

  const { sidebarL, sidebarR } = useContext(LayoutContext);

  const { user, expiresAt } = useSelector<RootState, SessionState>(
    (state) => state.session
  );

  useEffect(() => {
    dispatch(getTokenData());
  }, [dispatch]);

  useEffect(() => {
    let logoutTimeout: NodeJS.Timeout;
    if (expiresAt) {
      logoutTimeout = setTimeout(() => {
        dispatch(clearCache());
      }, expiresAt.getTime() - Date.now());
    }
    return () => {
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };
  }, [dispatch, expiresAt]);

  useEffect(() => {
    if (!user) {
      sidebarL.close();
      sidebarR.close();
    }
  }, [user, sidebarL, sidebarR]);
  return (
    <>
      {user && (
        <>
          <Navbar position="left">
            <button
              className={`text-2xl text-neutral-500 h-full py-1 px-2 mr-2 ${
                !sidebarL.hidden &&
                '!text-main-500 !border-b-main-500 !border-b-2 '
              }`}
              onClick={() => {
                if (sidebarL.hidden) {
                  sidebarL.open();
                } else {
                  sidebarL.close();
                }
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </Navbar>

          <LeftSidebar position="top">
            <NavLink
              to={'/cuenta'}
              role="button"
              className={({ isActive }) =>
                `btn-theme border border-theme ${
                  isActive && '!text-main-500 !border-r-main-500 !border-r-2'
                }`
              }
            >
              <AccountMinified user={user} withImg />
            </NavLink>
            <AccessList />
          </LeftSidebar>
          <LeftSidebar position="bottom">
            {
              <button
                className="btn-theme"
                onClick={() => {
                  dispatch(logout());
                  sidebarL.close();
                }}
              >
                Cerrar sesión
              </button>
            }
          </LeftSidebar>
        </>
      )}
    </>
  );
}
