import { Reducer } from "redux";

export type Interpreter<T = {
  [key: string]: any;
}> = T[];

export interface InterpretersState {
  [name: string]: Interpreter;
}

export interface InterpretersAction {
  type: "interpreters/set";
  interpreters: { [name: string]: Interpreter };
}
export const INITIAL_SESSION: InterpretersState = {};
export const interpretersReducer: Reducer<
  InterpretersState,
  InterpretersAction
> = (state = INITIAL_SESSION, action) => {
  if (action.type === "interpreters/set") {
    state = {
      ...state,
      ...action.interpreters,
    };
  }
  return state;
};
