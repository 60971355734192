import { useState, useEffect } from 'react';

function useFetch<R = Response>(
  input: RequestInfo,
  init?: RequestInit,
  map?: (response: Response) => Promise<R>
) {
  let [loading, setLoading] = useState<boolean>(true);
  let [response, setResponse] = useState<R | Response | null>(null);
  let [error, setError] = useState<any | null>(null);

  useEffect(() => {
    if (loading) {
      fetch(input, init)
        .then(async (res) => {
          if (res.ok) {
            if (map) {
              setResponse(await map(res));
            } else {
              setResponse(res);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    }
  }, [loading, input, init, map]);

  return {
    loading,
    response,
    error,
  };
}

export default useFetch;
