import { ModalOptions } from './modal';

type ModalProps = ModalOptions & {
    key: string;
    children?: React.ReactNode;
};

type ModalActionsType = '[Modal] add' | '[Modal] change' | '[Modal] remove';

export interface ModalReducerAction {
    type: ModalActionsType;
    key: string;
    options: ModalOptions & {
        children?: React.ReactNode;
    };
}

export const modalReducer: React.Reducer<ModalProps[], ModalReducerAction> = (
    state,
    action
) => {
    switch (action.type) {
        case '[Modal] add':
            state = [...state, createModal(action.key, action.options)];
            break;
        case '[Modal] change':
            state = state.map((modal) =>
                modal.key === action.key
                    ? {
                          ...modal,
                          ...createModal(action.key, action.options),
                      }
                    : modal
            );
            break;
        case '[Modal] remove':
            state = state.filter((modal) => modal.key !== action.key);
            break;
    }
    return state;
};

export function createModal(
    key: string = `_modal-${Date.now().toString(36)}`,
    options: ModalOptions & {
        children?: React.ReactNode;
    }
): ModalProps {
    return {
        ...options,
        key,
    };
}

export const modalActions = {
    add: (
        key: string,
        options: ModalOptions & {
            children?: React.ReactNode;
        }
    ): ModalReducerAction => ({
        type: '[Modal] add',
        key,
        options: options,
    }),

    change: (key: string, options: ModalOptions): ModalReducerAction => ({
        type: '[Modal] change',
        key,
        options: options,
    }),
    remove: (key: string): ModalReducerAction => ({
        type: '[Modal] remove',
        key,
        options: {},
    }),
};
