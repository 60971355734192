import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { Subject, Subscription } from 'rxjs';
import { Modal, ModalOptions } from './modal';
import { modalActions, modalReducer } from './modal.reducer';

interface ModalController {
    key: string;
    destroy: () => void;
    open: () => ModalController;
    close: (reason: string) => ModalController;
    onClose: (listener: (reason: string) => void) => Subscription;
}

interface ModalProviderOptions extends ModalOptions {
    destroyOnHide?: boolean;
}

export const ModalServiceContext = createContext<{
    createModal: (
        children: React.ReactNode,
        modalOptions?: ModalProviderOptions
    ) => ModalController;
}>({
    createModal: () => {
        throw new Error('Modal provider missing');
    },
});

export function ModalProvider({ children }: PropsWithChildren<{}>) {
    let [modals, dispatch] = useReducer(modalReducer, []);

    return (
        <ModalServiceContext.Provider
            value={{
                createModal(children, { destroyOnHide, ...modalOptions } = {}) {
                    let key = `_modal-${Date.now().toString(36)}`;
                    let onCloseSubject = new Subject<string>();
                    if (modalOptions.onClose) {
                        onCloseSubject.subscribe(modalOptions.onClose);
                    }

                    
                    let modalController: ModalController = {
                        key,
                        destroy: () => {
                            dispatch(modalActions.remove(key));
                        },
                        close: (reason: string) => {
                            onCloseSubject.next(reason);

                            
                            return modalController;
                        },
                        open: () => {
                            dispatch(
                                modalActions.change(key, {
                                    isOpen: true,
                                })
                            );
                            return modalController;
                        },
                        onClose: onCloseSubject.subscribe,
                    };
                    onCloseSubject.subscribe(()=>{
                        if (destroyOnHide !== false) {
                            modalController.destroy();
                        } else {
                            dispatch(
                                modalActions.change(key, {
                                    isOpen: false,
                                })
                            );
                        }
                    })
                    dispatch(
                        modalActions.add(key, {
                            ...modalOptions,
                            onClose: modalController.close,
                            children,
                        })
                    );

                    return modalController;
                },
            }}
        >
            {children}
            {modals.map((options) => (
                <Modal {...options} />
            ))}
        </ModalServiceContext.Provider>
    );
}
