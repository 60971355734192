import { PropsWithChildren } from 'react';

export function Sidebar({
  id,
  children,
  hidden,
  right,
  className,
  classNameOnOpen,
  classNameOnClose,
}: PropsWithChildren<{
  id?: string;
  hidden?: boolean;
  right?: boolean;
  className?: string;
  classNameOnOpen?: string;
  classNameOnClose?: string;
}>) {
  return (
    <aside
      id={id}
      className={
        'h-full content-theme border-theme border-x max-w-[400px] flex flex-col transform transition duration-200 min-w-[250px] ' +
        (hidden
          ? right
            ? `absolute right-0 translate-x-full ${classNameOnClose}`
            : `absolute left-0 -translate-x-full ${classNameOnClose}`
          : ` translate-x-0 ${classNameOnOpen}`) +
        ' ' +
        className
      }
    >
      {children}
    </aside>
  );
}
