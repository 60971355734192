import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Observable } from 'rxjs';
import './modal.css';

export interface ModalOptions {
  closeBtn?: boolean;
  closeOnOutSide?: boolean;
  isOpen?: boolean;
  size?: 'xl' | 'lg' | 'sm' | 'fs' | '';
  backdropClass?: string;
  windowClass?: string;
  onClose?: (reason: string) => void;
  overlay?: boolean;
  showController?: Observable<boolean>;
}

export type ModalProps = PropsWithChildren<ModalOptions>;

export function Modal({
  closeBtn,
  closeOnOutSide,
  isOpen,
  size,
  backdropClass,
  windowClass,
  onClose,
  overlay,
  showController,
  children,
}: ModalProps) {
  const [show, setShow] = useState(isOpen !== false);

  useEffect(() => {
    if (showController) {
      let subscription = showController.subscribe((show) => {
        setShow(show);
        if (!show && onClose) {
          onClose('showController');
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [showController, onClose]);
  useEffect(() => {
    setShow(isOpen !== false);
  }, [isOpen]);

  const closeHandler = (reason: string) => {
    setShow(false);
    if (onClose) onClose(reason);
  };

  return (
    <div className={'modal ' + (show && 'active')}>
      {overlay !== false && (
        <div
          className={`overlay ${show && 'active'} ` + backdropClass}
          onClick={() => {
            if (closeOnOutSide !== false) {
              closeHandler('click outside');
            }
          }}
        ></div>
      )}
      <div className={'modal-dialog  modal-' + size + ' ' + windowClass}>
        <div className="modal-content content-theme">
          <div className="modal-body modal-responsive ">{children}</div>
          {closeBtn !== false && (
            <button
              type="button"
              className="close"
              onClick={() => {
                closeHandler('cross button');
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
