import {
  combineReducers,
  createStore,
  Reducer,
  ReducersMapObject,
  Store,
  applyMiddleware,
  compose,
} from "redux";
import thunk from "redux-thunk";

import { interpretersReducer, InterpretersState } from "./reducers/interpreters.reducer";
import { loadingReducer, LoadingState } from "./reducers/loading.reducer";
import { sessionReducer, SessionState } from "./reducers/session.reducer";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Define the Reducers that will always be present in the application
const staticReducers = {
  session: sessionReducer,
  interpreters: interpretersReducer,
  loading: loadingReducer
};

export type RootState<
  T = {
    [key: string]: any;
  }
> = {
  session: SessionState;
  interpreters: InterpretersState;
  loading: LoadingState;
} & T;

// Configure the store
const store: Store & {
  asyncReducers: { [K: string]: Reducer };
  injectReducer: (key: string, asyncReducer: Reducer) => void;
} = createStore(createReducer(), composeEnhancers(applyMiddleware(thunk)));

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
  store.asyncReducers[key] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};

// Return the modified store

function createReducer(asyncReducers?: ReducersMapObject) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

export default store;
