import EffectBox from '../../common/components/EffectBox';

const Page401 = () => (
  <EffectBox
    className="content-theme p-3 mx-auto my-4 max-w-md border border-theme w-full"
    shadow
  >
    <h3 className="text-5xl">No tiene permisos para ver este contenido</h3>
  </EffectBox>
);

export default Page401;
