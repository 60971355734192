import Loading from '../../common/components/Loading';
import Navigation from '../../common/components/Navigation';
import useFetch from '../../common/hooks/useFetch';

const Apps: [string, string][] = [
  ['Solicitudes', '/solicitud/admin'],
  //['Trayectoria academica', '/trayectorias'],
  ['ServiceDesk', '/serviceDesk'],
  ['Préstamos', '/prestamos'],
];

function AccessList() {
  const { loading, response: lista } = useFetch<string[]>(
    '/api/accesos',
    undefined,
    (response) => response.json()
  );
  if (loading) {
    return <Loading />;
  }
  return (
    <Navigation
      links={Apps.filter(([_label, route]) =>
        (lista as string[]).includes(route)
      )}
      vertical
      borderOnActive="r"
      className="btn-theme"
    />
  );
}

export default AccessList;
