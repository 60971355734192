import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export type NavigationLinkType = [
  label: string | ReactNode,
  route: string,
  end?: boolean
];

interface NavigationProps {
  links: NavigationLinkType[];
  borderOnActive?: 'r' | 'l' | 'b' | 't';
  className?: string;
  vertical?: boolean;
}

const classBorder = {
  b: '!border-b-2',
  t: '!border-t-2',
  r: '!border-r-2',
  l: '!border-l-2',
};
const Navigation = ({
  links,
  borderOnActive,
  className,
  vertical,
}: NavigationProps) => (
  <>
    {links.map(([label, route, end]) => (
      <NavLink
        role="button"
        className={({ isActive }) =>
          [
            '!bg-white dark:!bg-neutral-800  btn-theme',
            'text-black dark:text-white',
            vertical ? 'w-full' : 'h-full',
            className || '',
            isActive &&
              `!text-main-500 ${
                borderOnActive &&
                `!border-main-500 ${classBorder[borderOnActive]}`
              } `,
          ].join(' ')
        }
        key={route}
        to={route}
        end={end}
      >
        {label}
      </NavLink>
    ))}
  </>
);

export default Navigation;
