import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';
import { ModalProps, Modal } from './modal';

export function PropertyModal({
  yes,
  no,
  resolve,
  children,
  showController,
  initialValues,
  ...rest
}: ModalProps & {
  initialValues: any;
  yes?: string;
  no?: string | null;
  resolve: (value: any) => void;
}) {
  let showSubject = useMemo(() => new Subject<boolean>(), []);

  useEffect(() => {
    if (showController) {
      let subscription = showController.subscribe(showSubject.next);
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [showController, showSubject.next]);

  return (
    <Modal
      {...rest}
      closeOnOutSide={true}
      showController={showSubject.asObservable()}
    >
      <PropertyModalChildren
        initialValues={initialValues}
        onNoHandler={() => {
          showSubject.next(false);
          resolve(false);
        }}
        onSubmitHandler={(values) => {
          showSubject.next(false);
          resolve(values);
        }}
        children={children}
        no={no}
        yes={yes}
      />
    </Modal>
  );
}

export const PropertyModalChildren = ({
  yes,
  no,
  children,
  onSubmitHandler,
  onNoHandler,
  initialValues,
}: {
  children?: React.ReactNode;
  yes?: string;
  no?: string | null;
  onSubmitHandler: (values: any) => void;
  onNoHandler: () => void;
  initialValues: any;
}) => (
  <Formik onSubmit={onSubmitHandler} initialValues={initialValues}>
    <Form>
      <div>{children}</div>
      <div className="border-t border-theme">
        <button className="btn-main">
          {yes || propertyModalDefaults.yesLabel}
        </button>
        {no !== null && (
          <button className="btn-theme" onClick={onNoHandler}>
            {no || propertyModalDefaults.noLabel}
          </button>
        )}
      </div>
    </Form>
  </Formik>
);

export const propertyModalDefaults: {
  yesLabel: string;
  noLabel: string;
} = {
  yesLabel: 'Send',
  noLabel: 'Cancel',
};
