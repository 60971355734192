import { PropsWithChildren, RefObject, useLayoutEffect, useRef } from 'react';

type EffectBoxProps = PropsWithChildren<{
  badge?: boolean;
  pulse?: boolean;
  bounce?: boolean;
  shadow?: boolean;
  className?: string;
  onLoad?: (ref: RefObject<HTMLDivElement>) => void | (() => void);
}>;

const EffectBox = ({
  pulse,
  badge,
  bounce,
  shadow,
  children,
  className,
  onLoad,
}: EffectBoxProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (onLoad) return onLoad(divRef);
  }, [divRef]);

  return (
    <div
      ref={divRef}
      className={`relative ${pulse ? 'animate-pulse' : ''} ${
        bounce ? 'animate-bounce' : ''
      } ${className || ''} ${shadow ? 'shadow-xl' : ''}`}
    >
      {children}
      {badge && (
        <span className="flex h-3 w-3 absolute top-0 right-0 -mt-1 -mr-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-main-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-main-500"></span>
        </span>
      )}
    </div>
  );
};

export default EffectBox;
